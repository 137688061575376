<template>
    <div class="flex flex-col bg-card-bg rounded-2xl border-2 pack-section-cnt"  :class="{'tu-section-expand':getcheckTucheck}" v-if="sectionData && sectionData.length">
        <div class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
            Sections
            <div class="flex gap-3 items-center">
                <span class="text-base-content text-sm flex items-center gap-2 cursor-pointer" v-if="isSectionExpanded"
                    @click="expandSections"><font-awesome-icon icon="chevron-circle-up"
                        class="text-base-content cursor-pointer text-lg" /> Collapse All</span>
                <span class="text-base-content text-sm flex items-center gap-2 cursor-pointer" v-else
                    @click="expandSections"><font-awesome-icon icon="chevron-circle-down"
                        class="text-base-content cursor-pointer text-lg" /> Expand All </span>
            </div>
        </div>
        <div class="w-full p-4 gap-2 flex flex-col overflow-auto scroll-bar scrollDiv">
            <div class="w-max min-w-full gap-2 flex flex-col">
                <transition>
                    <div class="pl-12 w-full flex" v-if="isAnySectionExpanded">
                        <RolesHeader class="role-panel ml-auto pr-3" :class="getClass()"/>
                    </div>
                </transition>
                <div class="flex flex-col gap-6" :class="getClassString" v-if="getDragableCondition">
                    <draggable data-source="sectionData" :list="sectionData"  draggable='.item' group="section" v-bind="dragOptions" @start="startDrag = true" @end="startDrag = false" class="list-group flex flex-col gap-4 " @change="sectionReorder($event)">
                        <div class="list-group-item item shadow-sm rounded-xl bg-gray-100 p-3 border-0 flex flex-col gap-3 border-gray-300 border-solid" v-for="section in sectionList" :key="section.id">
                            <sectionDragable
                                :isUserPermission="isUserPermission"
                                :showSidePanel="showSidePanel"
                                @handleShowPanel="handleShowPanel($event.field, $event.section)"
                                @fieldNameClick="fieldNameClick($event)"
                                @handleDeleteField="handleDeleteField($event.field, $event.section)"
                                @updateFieldLabel="updateFieldLabel" @handleFieldMove="handleFieldMove($event)"
                                @handleRemoveBlock="handleRemoveBlock($event.section, $event.element)"
                                @handleFieldReorder="handleFieldReorder($event, $event.element)"
                                @handleAddFieldChange="handleAddFieldChange($event)" :section="section"
                                @handleCollapseBlock="handleCollapseBlock($event.section, $event.element)"
                                :checkData="checkData" @AddNewBlock="AddNewBlock" @handleRemoveSection="handleRemoveSection"
                                :activeFieldPanel="activeFieldPanel"
                                @handleUpdateBlock="handleUpdateBlock($event.section, $event.element)"
                                @saveBlockFields="saveBlockFields($event.section, $event.element)"
                                @showfieldAddModal="showfieldAddModal($event.section, $event.element)"
                                @showBlockModal="$emit('showBlockModal', $event)"
                                @updateSectionLabel="$emit('updateSectionLabel', $event)"
                                @PermissionChange="PermissionChange" :hideAddFieldButtonInBlock="hideAddFieldButtonInBlock"
                                :allReportsRoles="allReportsRoles"
                                @showFieldSettingsModal="showFieldSettingsModal($event.field, $event.section)"
                                :updateSectionFieldsMethod="updateSectionFieldsMethod"
                                :updateBlockFieldsMethod="updateBlockFieldsMethod" @changeCaseCreation="changeCaseCreation"
                                @changeblockVisibile="changeblockVisibile"
                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                @showGapValidationModal="showGapValidationModal($event.field, $event.section)"
                                :queryData="queryData"
                                :changeMethod="changeMethod" 
                                :changeCaseCreationMethod="changeCaseCreationMethod"/>
                        </div>
                    </draggable>
                </div>
                <div class="flex flex-col gap-6" :class="getClassString" v-else>
                    <!--<draggable data-source="sectionData" :list="sectionData"  :draggable="queryData=='edit' ? '.item' : null" group="section" v-bind="dragOptions" @start="startDrag = true" @end="startDrag = false" class="list-group flex flex-col gap-4 " @change="sectionReorder($event)">-->
                        <div class="list-group-item item shadow-sm rounded-xl bg-gray-100 p-3 border-0 flex flex-col gap-3 border-gray-300 border-solid" v-for="section in sectionList" :key="section.id">
                            <sectionDragable
                                :isUserPermission="isUserPermission"
                                :showSidePanel="showSidePanel"
                                @handleShowPanel="handleShowPanel($event.field, $event.section)"
                                @fieldNameClick="fieldNameClick($event)"
                                @handleDeleteField="handleDeleteField($event.field, $event.section)"
                                @updateFieldLabel="updateFieldLabel"
                                @handleFieldMove="handleFieldMove($event)"
                                @handleRemoveBlock="handleRemoveBlock($event.section, $event.element)"
                                @handleFieldReorder="handleFieldReorder($event, $event.element)"
                                @handleAddFieldChange="handleAddFieldChange($event)"
                                :section="section"
                                @handleCollapseBlock="handleCollapseBlock($event.section, $event.element)"
                                :checkData="checkData"
                                @AddNewBlock="AddNewBlock"
                                @handleRemoveSection="handleRemoveSection"
                                :activeFieldPanel="activeFieldPanel"
                                @handleUpdateBlock="handleUpdateBlock($event.section, $event.element)"
                                @saveBlockFields="saveBlockFields($event.section, $event.element)"
                                @showfieldAddModal="showfieldAddModal($event.section, $event.element)"
                                @showBlockModal="$emit('showBlockModal', $event)"
                                @updateSectionLabel="$emit('updateSectionLabel', $event)"
                                @PermissionChange="PermissionChange"
                                :hideAddFieldButtonInBlock="hideAddFieldButtonInBlock"
                                :allReportsRoles="allReportsRoles"
                                @showFieldSettingsModal="showFieldSettingsModal($event.field, $event.section)"
                                :updateSectionFieldsMethod="updateSectionFieldsMethod"
                                :updateBlockFieldsMethod="updateBlockFieldsMethod"
                                @changeCaseCreation="changeCaseCreation"
                                @changeblockVisibile="changeblockVisibile"
                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                @showGapValidationModal="showGapValidationModal($event.field, $event.section)"
                                :queryData="queryData"
                                :changeMethod="changeMethod"
                                :changeCaseCreationMethod="changeCaseCreationMethod"
                            />
                        </div>
                   <!-- </draggable>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sectionDragable from "./dragable-components/sectionDragable.vue";
import draggable from "vuedraggable";
import RolesHeader from "./roles-header";
import axios from "@/axios";
import {checkPermission} from "@shared/utils/functions"


export default {
    name: "section-lists",
    components: {
        sectionDragable,
        draggable,
        RolesHeader,
    },
    data() {
        return {
            queryData: null,
            isFixed: false,
            pageName:'Package Tools'
        };
    },
    props: ["sectionList", "allSectionExpanded", "checkData", "showSidePanel", "activeFieldPanel", "hideAddFieldButtonInBlock", "allReportsRoles", "updateSectionFieldsMethod", "updateBlockFieldsMethod", "updateFieldAttributesMethod", "isUserPermission","shouldShowAdditionalContent",'changeMethod','changeCaseCreationMethod'],
    computed: {
        getClassString() {
            let classes = '';

            if (this.$route.params.packagetool === "form-editor") {
                classes += 'overflow-y-auto scroll package-fixed-div';
            }
            if (this.$route.name === "form admin details") {
                classes += 'overflow-y-auto scroll fixed-div';
            }
            if (this.shouldShowAdditionalContent) {
                classes += 'overflow-y-auto scroll fixed-div ';
            }
            return classes.trim();
    },
    getcheckTucheck() {
        if (this.$route.params.packagetool === "form-editor") {
                return false;
            }
            if (this.$route.name === "form admin details") {
                return true
            }
            if (this.shouldShowAdditionalContent && this.checkData.parameters !== null ) {
                return true
            }
            return false;
    },
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
        isSectionExpanded() {
            return this.sectionList.every(el => {
                return el.expanded && el.fields.every(fld => {
                    if (fld.entity_type === 'block') return fld.expanded
                    else return true
                })
            }) ?? false
            // return this.allSectionExpanded;
        },
        isAnySectionExpanded() {
            return this.sectionData.some(sec => sec.expanded);
        },
        sectionData() {
            return this.sectionList;
        },
        ISEDITPERMISSIONS() {
            if (this.isUserPermission) {
                return (this.isUserPermission.SHOW_ALL_ACTIONS)
            } else {
                return true
            }
        },
        getDragableCondition() {
            if(this.queryData) {
                if(this.queryData == 'edit') {
                    return true;
                } else {
                    return false;
                }
            } else {
                if(checkPermission('form.edit')) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    },
    mounted() {
    
        this.queryData = this.$route.params?.action
        this.pageName=this.$route.name ;
    },
    methods: {
        expandSections() {
            this.$emit("handleExpandAllSections",this.isSectionExpanded);
        },
        async handleFieldReorder(event, element) {
            let package_id = this.$route.params.packageName;
            let section_id = event?.event?.moved?.element?.form_section_id || event?.event?.event?.moved?.element?.form_section_id;
            let field_list = [];
            element.fields.forEach((el, index) => field_list.push({ block_field_id: el.id, order: index + 1 }));
            let payload = {
                field_list,
                section_id
            };
            if (event?.event?.moved || event?.event?.event?.moved) {
                const url = `/package-section/${package_id}/block/field/reorder`;
                try {
                    const { data } = await axios.post(url, payload);
                    this.$toast.success(data.message);
                } catch (error) {
                    this.$toast.warning(error.response.data.detail || "Failed to reorder Field!");
                }
                // this.$toast.success("Section Reordered");
            }
        },
        startDrag() {
            this.$emit("drag");
        },
        sectionReorder(event) {
            if (this.$route.params.action == 'view') {
                return
            }
            this.$emit("sectionReorder", event);
        },
        AddNewBlock(payload) {
            this.$emit("AddNewBlock", payload);
        },
        handleRemoveSection(sectionData) {
            this.$emit("handleRemoveSection", sectionData);
        },
        handleShowPanel(field, section) {
            this.$emit("handleShowPanel", { field, section });
        },
        fieldNameClick(field) {
            this.$emit("fieldNameClick", field);
        },
        handleDeleteField(field, section) {
            if (this.$route.params.action !== 'view') {
                this.$emit("handleDeleteField", { field, section });
            }
        },
        updateFieldLabel(e) {
            this.$emit("updateFieldLabel", e);
        },
        handleRemoveBlock(section, element) {
            const block = element || section?.element
            this.$emit("handleRemoveBlock", { section, block, element: block });
        },
        handleCollapseBlock(section, element) {
            const block = element || section?.element
            this.$emit("handleCollapseBlock", { section, block, element: block });
        },
        handleUpdateBlock(section, element) {
            const block = element || section?.element
            this.$emit("handleUpdateBlock", { section, block, element: block });
        },
        saveBlockFields(section, element) {
            const block = element || section?.element
            this.$emit("saveBlockFields", { section, block, element: block });
        },
        showfieldAddModal(section, element) {
            const block = element || section?.element
            this.$emit("showfieldAddModal", { section, block, element: block });

        },
        handleFieldMove($event) {
            if (this.$route.params.action == 'view') {
                return
            }
            this.$emit("handleFieldMove", $event);
        },

        handleAddFieldChange($event) {
            this.$emit("handleAddFieldChange", $event);
        },
        PermissionChange(role) {
            this.$emit("PermissionChange", role);
        },
        showFieldSettingsModal(field, section) {
            this.$emit("showFieldSettingsModal", { field, section });
        },
        showGapValidationModal(field, section) {
            this.$emit("showGapValidationModal", { field, section });
        },
        changeCaseCreation(payload) {
            this.$emit('changeCaseCreation', payload);
        },
        changeblockVisibile(payload) {
            this.$emit('changeblockVisibile', payload);
        },
        getClass() {
            
            if(this.pageName==='check admin details'){
                if(this.queryData === 'view') {
                return 'CheckroleHeaderView';
            } 
            else{
                return 'CheckroleHeaderEdit';
            }
            }
            else if(this.pageName==='Package Tools'){
            if(this.queryData === 'view') {
                return 'PackageroleHeaderView';
            }
            else{
                return 'PackageroleHeaderEdit';
            }
            }else if(this.pageName==='form admin details'){
                     return 'FormAdminRoleHead'
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.role-panel {
    padding-left: 400px;
}
.scrollDiv{
    height: auto;
    min-height: 200px;
}

.fixed-div {
    max-height: 50vh;
}
.package-fixed-div{
    max-height: 55vh;
}
.pack-section-cnt{
    min-height: 44vh;
    // margin-top:55px
}
.tu-section-expand{
    margin-top:65px
}
.CheckroleHeaderEdit{
    margin-left: 23.7%;
}
.CheckroleHeaderView{
    margin-left: 23.7%;
}
.PackageroleHeaderEdit{
    margin-left: 23.7%;
}
.PackageroleHeaderView
{
    margin-left: 23.6%;
}
.FormAdminRoleHead{
    margin-left: 23.6%;
}
</style>
